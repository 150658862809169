<table
  mat-table
  [dataSource]="dataSource"
  matSort
  (matSortChange)="sortData($event)"
>
  <!-- Name Column -->
  <ng-container matColumnDef="fileName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "Name" | transloco }}
    </th>
    <td mat-cell *matCellDef="let attachment">{{ attachment.fileName }}</td>
  </ng-container>

  <!-- Type Column -->
  <ng-container matColumnDef="fileType">
    <th mat-header-cell *matHeaderCellDef>{{ "Type" | transloco }}</th>
    <td mat-cell *matCellDef="let attachment">{{ attachment.fileType }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="downloadAttachment(row.id, row.fileName)"
  ></tr>
</table>

<mat-paginator
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [length]="totalItems"
  showFirstLastButtons
  (page)="onPageChange($event)"
></mat-paginator>
