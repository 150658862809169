/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { downloadAppendixDocument } from '../fn/invoice/download-appendix-document';
import { DownloadAppendixDocument$Params } from '../fn/invoice/download-appendix-document';
import { downloadInvoiceDocument } from '../fn/invoice/download-invoice-document';
import { DownloadInvoiceDocument$Params } from '../fn/invoice/download-invoice-document';
import { get } from '../fn/invoice/get';
import { Get$Params } from '../fn/invoice/get';
import { Invoice } from '../models/invoice';
import { listInvoices } from '../fn/invoice/list-invoices';
import { ListInvoices$Params } from '../fn/invoice/list-invoices';

@Injectable({ providedIn: 'root' })
export class InvoiceService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `get()` */
  static readonly GetPath = '/api/Invoice/{invoiceID}';

  /**
   * Get an invoice by it's ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<Invoice>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an invoice by it's ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: Get$Params, context?: HttpContext): Observable<Invoice> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<Invoice>): Invoice => r.body)
    );
  }

  /** Path part for operation `downloadAppendixDocument()` */
  static readonly DownloadAppendixDocumentPath = '/api/Invoice/{invoiceID}/appendix';

  /**
   * Donwload the appendix (pdf) document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAppendixDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAppendixDocument$Response(params: DownloadAppendixDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadAppendixDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Donwload the appendix (pdf) document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAppendixDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAppendixDocument(params: DownloadAppendixDocument$Params, context?: HttpContext): Observable<void> {
    return this.downloadAppendixDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadInvoiceDocument()` */
  static readonly DownloadInvoiceDocumentPath = '/api/Invoice/{invoiceID}/document';

  /**
   * Donwload the invoice (pdf) document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadInvoiceDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadInvoiceDocument$Response(params: DownloadInvoiceDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return downloadInvoiceDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Donwload the invoice (pdf) document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadInvoiceDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadInvoiceDocument(params: DownloadInvoiceDocument$Params, context?: HttpContext): Observable<void> {
    return this.downloadInvoiceDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `listInvoices()` */
  static readonly ListInvoicesPath = '/api/Invoice';

  /**
   * Lists invoices (paged).
   *
   * Paging (page and size) can be supplied through headers or query parameters (Query parameter values have precedence over headers).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listInvoices()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoices$Response(params?: ListInvoices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Invoice>>> {
    return listInvoices(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists invoices (paged).
   *
   * Paging (page and size) can be supplied through headers or query parameters (Query parameter values have precedence over headers).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listInvoices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoices(params?: ListInvoices$Params, context?: HttpContext): Observable<Array<Invoice>> {
    return this.listInvoices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Invoice>>): Array<Invoice> => r.body)
    );
  }

}
