import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { ConfigLoaderService } from 'src/app/services/config/config-loader.service';
import { Configuration } from 'src/app/services/config/configuration';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authConfig!: AuthConfig;

  constructor(private oauthService: OAuthService, private configLoaderService: ConfigLoaderService) {
    this.loadConfigAndConfigureOAuth();
  }

  private loadConfigAndConfigureOAuth(): void {
    this.configLoaderService.loadConfigurations().subscribe(
      data => {
        this.authConfig = { ...data };
        this.configureOAuth();
      },
      error => {
        console.error('Error fetching config:', error);
      }
    );
  }



  private configureOAuth(): void {
    this.oauthService.configure(this.authConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  get isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }
}
