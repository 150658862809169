/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createOrder } from '../fn/order-service/create-order';
import { CreateOrder$Params } from '../fn/order-service/create-order';
import { deleteOrder } from '../fn/order-service/delete-order';
import { DeleteOrder$Params } from '../fn/order-service/delete-order';
import { getOrder } from '../fn/order-service/get-order';
import { GetOrder$Params } from '../fn/order-service/get-order';
import { getOrders } from '../fn/order-service/get-orders';
import { GetOrders$Params } from '../fn/order-service/get-orders';
import { OrderDto } from '../models/order-dto';
import { PageOfOrderDto } from '../models/page-of-order-dto';
import { updateOrder } from '../fn/order-service/update-order';
import { UpdateOrder$Params } from '../fn/order-service/update-order';


/**
 * OrderService
 */
@Injectable({ providedIn: 'root' })
export class OrderService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOrders()` */
  static readonly GetOrdersPath = '/orders';

  /**
   * getOrders.
   *
   * Fetch list of orders
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrders$Response(params: GetOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOfOrderDto>> {
    return getOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * getOrders.
   *
   * Fetch list of orders
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrders(params: GetOrders$Params, context?: HttpContext): Observable<PageOfOrderDto> {
    return this.getOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOfOrderDto>): PageOfOrderDto => r.body)
    );
  }

  /** Path part for operation `updateOrder()` */
  static readonly UpdateOrderPath = '/orders';

  /**
   * updateOrder.
   *
   * Update an order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrder$Response(params: UpdateOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderDto>> {
    return updateOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * updateOrder.
   *
   * Update an order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrder(params: UpdateOrder$Params, context?: HttpContext): Observable<OrderDto> {
    return this.updateOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderDto>): OrderDto => r.body)
    );
  }

  /** Path part for operation `createOrder()` */
  static readonly CreateOrderPath = '/orders';

  /**
   * createOrder.
   *
   * Create order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrder$Response(params: CreateOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderDto>> {
    return createOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * createOrder.
   *
   * Create order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrder(params: CreateOrder$Params, context?: HttpContext): Observable<OrderDto> {
    return this.createOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderDto>): OrderDto => r.body)
    );
  }

  /** Path part for operation `getOrder()` */
  static readonly GetOrderPath = '/orders/{orderId}';

  /**
   * getOrder.
   *
   * Get order by Id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrder$Response(params: GetOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderDto>> {
    return getOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * getOrder.
   *
   * Get order by Id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrder(params: GetOrder$Params, context?: HttpContext): Observable<OrderDto> {
    return this.getOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderDto>): OrderDto => r.body)
    );
  }

  /** Path part for operation `deleteOrder()` */
  static readonly DeleteOrderPath = '/orders/{orderId}';

  /**
   * deleteOrder.
   *
   * Delete order by ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrder$Response(params: DeleteOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderDto>> {
    return deleteOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * deleteOrder.
   *
   * Delete order by ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrder(params: DeleteOrder$Params, context?: HttpContext): Observable<OrderDto> {
    return this.deleteOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderDto>): OrderDto => r.body)
    );
  }

}
