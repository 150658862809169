/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InvoiceType } from '../../models/invoice-type';

export interface ListInvoiceTypes$Json$Params {
  culture?: string;
}

export function listInvoiceTypes$Json(http: HttpClient, rootUrl: string, params?: ListInvoiceTypes$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InvoiceType>>> {
  const rb = new RequestBuilder(rootUrl, listInvoiceTypes$Json.PATH, 'get');
  if (params) {
    rb.query('culture', params.culture, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<InvoiceType>>;
    })
  );
}

listInvoiceTypes$Json.PATH = '/api/Enumeration/InvoiceTypes';
