/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Invoice } from '../../models/invoice';

export interface ListInvoices$Params {

/**
 * page number. defaults to 1
 */
  page?: number;

/**
 * page size. defaults to 10
 */
  size?: number;

/**
 * page number. defaults to 1
 */
  'X-Pagination-Page'?: number;

/**
 * page size. defaults to 10
 */
  'X-Pagination-Size'?: number;

/**
 * specify list ordering in format: fieldname(,asc|desc) or (+|-)fieldname. Multiple order statements are allowed
 */
  order?: Array<string>;

/**
 * limit results to the given wallet
 */
  walletID?: string;
  ownerID?: string;

/**
 * limit results to given invoice types (must be valid codes from the InvoiceTypes enumeration)
 */
  type?: Array<string>;

/**
 * limit results to given invoice states (must be valid codes from the InvoiceStates enumeration)
 */
  state?: Array<string>;

/**
 * filter results to the given year
 */
  year?: number;

/**
 * Filter results to the given month
 */
  month?: number;

/**
 * Limit results to invoices over specified amount
 */
  minAmount?: number;

/**
 * Limit results to invoices under specified amount
 */
  maxAmount?: number;

/**
 * Limit results to invoices with the given Tenant as Invoice Supplier
 */
  supplierID?: string;

/**
 * Limit results to invoices with the given Tenant as Invoice Customer
 */
  customerID?: string;
}

export function listInvoices(http: HttpClient, rootUrl: string, params?: ListInvoices$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Invoice>>> {
  const rb = new RequestBuilder(rootUrl, listInvoices.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.header('X-Pagination-Page', params['X-Pagination-Page'], {});
    rb.header('X-Pagination-Size', params['X-Pagination-Size'], {});
    rb.query('order', params.order, {});
    rb.query('walletID', params.walletID, {});
    rb.query('ownerID', params.ownerID, {});
    rb.query('type', params.type, {});
    rb.query('state', params.state, {});
    rb.query('year', params.year, {});
    rb.query('month', params.month, {});
    rb.query('minAmount', params.minAmount, {});
    rb.query('maxAmount', params.maxAmount, {});
    rb.query('supplierID', params.supplierID, {});
    rb.query('customerID', params.customerID, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<Invoice>>;
    })
  );
}

listInvoices.PATH = '/api/Invoice';
