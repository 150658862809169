/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { WalletBalance } from '../../models/wallet-balance';

export interface ListWalletBalances$Params {

/**
 * page number. defaults to 1
 */
  page?: number;

/**
 * page size. defaults to 10
 */
  size?: number;

/**
 * page number. defaults to 1
 */
  'X-Pagination-Page'?: number;

/**
 * page size. defaults to 10
 */
  'X-Pagination-Size'?: number;

/**
 * specify list ordering in format: fieldname(,asc|desc) or (+|-)fieldname. Multiple order statements are allowed
 */
  order?: Array<string>;

/**
 * limit results to given Wallet types (must be valid codes from the WalletType enumeration)
 */
  type?: Array<string>;

/**
 * search wallets linked to the given organization number
 */
  organizationNumber?: string;

/**
 * search wallets linked to the given owner name
 */
  ownerName?: string;

/**
 * Limit results to invoices over specified amount
 */
  minAmount?: number;

/**
 * Limit results to invoices under specified amount
 */
  maxAmount?: number;
}

export function listWalletBalances(http: HttpClient, rootUrl: string, params?: ListWalletBalances$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WalletBalance>>> {
  const rb = new RequestBuilder(rootUrl, listWalletBalances.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.header('X-Pagination-Page', params['X-Pagination-Page'], {});
    rb.header('X-Pagination-Size', params['X-Pagination-Size'], {});
    rb.query('order', params.order, {});
    rb.query('type', params.type, {});
    rb.query('organizationNumber', params.organizationNumber, {});
    rb.query('ownerName', params.ownerName, {});
    rb.query('minAmount', params.minAmount, {});
    rb.query('maxAmount', params.maxAmount, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<WalletBalance>>;
    })
  );
}

listWalletBalances.PATH = '/api/WalletBalance';
