<div class="container">
  <div *ngIf="isLoading; else showForm">
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #showForm>
    <form [formGroup]="orderForm" (ngSubmit)="onSave()">
      <mat-form-field>
        <mat-label>{{ "Product Name" | transloco }}</mat-label>
        <input matInput formControlName="productName" required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Net Amount" | transloco }}</mat-label>
        <input matInput formControlName="netAmount" required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Order Reference ID" | transloco }}</mat-label>
        <input matInput formControlName="orderReference" readonly />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Transaction Reference ID" | transloco }}</mat-label>
        <input matInput formControlName="transactionReferenceId" readonly />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Payment state" | transloco }}</mat-label>
        <mat-select formControlName="state">
          @for (state of paymentStates; track state) {
          <mat-option [value]="state">{{ state }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Payment state" | transloco }}</mat-label>
        <mat-select formControlName="type">
          @for (type of purchaseTypes; track type) {
          <mat-option [value]="type">{{ type }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Number" | transloco }}</mat-label>
        <input matInput formControlName="number" readonly />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Created Date" | transloco }}</mat-label>
        <input matInput formControlName="createdDate" readonly />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Last Modified Date" | transloco }}</mat-label>
        <input matInput formControlName="lastModifiedDate" readonly />
      </mat-form-field>

      <button
        mat-raised-button
        type="submit"
        [disabled]="orderForm.get('state')?.value === 'COMPLETED'"
      >
        {{ "Save" | transloco }}
      </button>
      <mat-divider></mat-divider>
      <ng-container
        *ngIf="mode === 'edit' && orderForm.get('state')?.value !== 'COMPLETED'"
      >
        <button mat-raised-button color="primary" (click)="doPayment()">
          {{ "Do payment" | transloco }}
        </button>
      </ng-container>
      <ng-container
        *ngIf="mode === 'edit' && orderForm.get('state')?.value !== 'COMPLETED'"
      >
        <mat-divider></mat-divider>
        <button mat-raised-button color="warn" (click)="deleteOrder()">
          {{ "Delete" | transloco }}
        </button>
      </ng-container>
    </form>
  </ng-template>
</div>
