<mat-sidenav-container class="sidenav-container">
  <mat-sidenav mode="side" opened>
    <mat-toolbar>
      <app-logo></app-logo>
    </mat-toolbar>
    <mat-nav-list>
      <mat-list-item>
        <button mat-button routerLink="/home">{{ "Home" | transloco }}</button>
      </mat-list-item>
      <mat-list-item>
        <button mat-button routerLink="/persons">
          {{ "Persons" | transloco }}
        </button>
      </mat-list-item>
      <mat-list-item>
        <button mat-button routerLink="/orders">
          {{ "Orders" | transloco }}
        </button>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item class="language-switch">
        <app-language-switch></app-language-switch>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <span>{{ "Demo Application" | transloco }}</span>
      <span class="spacer"></span>
      <app-logout *ngIf="isLoggedIn; else showLogin"></app-logout>
      <ng-template #showLogin>
        <app-login></app-login>
      </ng-template>
    </mat-toolbar>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
