/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { InvoiceState } from '../models/invoice-state';
import { InvoiceType } from '../models/invoice-type';
import { listInvoiceStates$Json } from '../fn/enumeration/list-invoice-states-json';
import { ListInvoiceStates$Json$Params } from '../fn/enumeration/list-invoice-states-json';
import { listInvoiceStates$Plain } from '../fn/enumeration/list-invoice-states-plain';
import { ListInvoiceStates$Plain$Params } from '../fn/enumeration/list-invoice-states-plain';
import { listInvoiceTypes$Json } from '../fn/enumeration/list-invoice-types-json';
import { ListInvoiceTypes$Json$Params } from '../fn/enumeration/list-invoice-types-json';
import { listInvoiceTypes$Plain } from '../fn/enumeration/list-invoice-types-plain';
import { ListInvoiceTypes$Plain$Params } from '../fn/enumeration/list-invoice-types-plain';
import { listOperationTypes$Json } from '../fn/enumeration/list-operation-types-json';
import { ListOperationTypes$Json$Params } from '../fn/enumeration/list-operation-types-json';
import { listOperationTypes$Plain } from '../fn/enumeration/list-operation-types-plain';
import { ListOperationTypes$Plain$Params } from '../fn/enumeration/list-operation-types-plain';
import { listTaxTypes$Json } from '../fn/enumeration/list-tax-types-json';
import { ListTaxTypes$Json$Params } from '../fn/enumeration/list-tax-types-json';
import { listTaxTypes$Plain } from '../fn/enumeration/list-tax-types-plain';
import { ListTaxTypes$Plain$Params } from '../fn/enumeration/list-tax-types-plain';
import { listTransactionStateTypes$Json } from '../fn/enumeration/list-transaction-state-types-json';
import { ListTransactionStateTypes$Json$Params } from '../fn/enumeration/list-transaction-state-types-json';
import { listTransactionStateTypes$Plain } from '../fn/enumeration/list-transaction-state-types-plain';
import { ListTransactionStateTypes$Plain$Params } from '../fn/enumeration/list-transaction-state-types-plain';
import { listTransactionTypes$Json } from '../fn/enumeration/list-transaction-types-json';
import { ListTransactionTypes$Json$Params } from '../fn/enumeration/list-transaction-types-json';
import { listTransactionTypes$Plain } from '../fn/enumeration/list-transaction-types-plain';
import { ListTransactionTypes$Plain$Params } from '../fn/enumeration/list-transaction-types-plain';
import { listWalletTypes$Json } from '../fn/enumeration/list-wallet-types-json';
import { ListWalletTypes$Json$Params } from '../fn/enumeration/list-wallet-types-json';
import { listWalletTypes$Plain } from '../fn/enumeration/list-wallet-types-plain';
import { ListWalletTypes$Plain$Params } from '../fn/enumeration/list-wallet-types-plain';
import { OperationType } from '../models/operation-type';
import { TaxType } from '../models/tax-type';
import { TransactionStateType } from '../models/transaction-state-type';
import { TransactionType } from '../models/transaction-type';
import { WalletType } from '../models/wallet-type';

@Injectable({ providedIn: 'root' })
export class EnumerationService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listWalletTypes()` */
  static readonly ListWalletTypesPath = '/api/Enumeration/WalletTypes';

  /**
   * Lists the possible types of Wallets.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWalletTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletTypes$Plain$Response(params?: ListWalletTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WalletType>>> {
    return listWalletTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Wallets.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listWalletTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletTypes$Plain(params?: ListWalletTypes$Plain$Params, context?: HttpContext): Observable<Array<WalletType>> {
    return this.listWalletTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WalletType>>): Array<WalletType> => r.body)
    );
  }

  /**
   * Lists the possible types of Wallets.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWalletTypes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletTypes$Json$Response(params?: ListWalletTypes$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WalletType>>> {
    return listWalletTypes$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Wallets.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listWalletTypes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletTypes$Json(params?: ListWalletTypes$Json$Params, context?: HttpContext): Observable<Array<WalletType>> {
    return this.listWalletTypes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WalletType>>): Array<WalletType> => r.body)
    );
  }

  /** Path part for operation `listTransactionTypes()` */
  static readonly ListTransactionTypesPath = '/api/Enumeration/TransactionTypes';

  /**
   * Lists the possible types of Transactions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTransactionTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactionTypes$Plain$Response(params?: ListTransactionTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransactionType>>> {
    return listTransactionTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Transactions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTransactionTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactionTypes$Plain(params?: ListTransactionTypes$Plain$Params, context?: HttpContext): Observable<Array<TransactionType>> {
    return this.listTransactionTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransactionType>>): Array<TransactionType> => r.body)
    );
  }

  /**
   * Lists the possible types of Transactions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTransactionTypes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactionTypes$Json$Response(params?: ListTransactionTypes$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransactionType>>> {
    return listTransactionTypes$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Transactions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTransactionTypes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactionTypes$Json(params?: ListTransactionTypes$Json$Params, context?: HttpContext): Observable<Array<TransactionType>> {
    return this.listTransactionTypes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransactionType>>): Array<TransactionType> => r.body)
    );
  }

  /** Path part for operation `listTransactionStateTypes()` */
  static readonly ListTransactionStateTypesPath = '/api/Enumeration/TransactionStates';

  /**
   * Lists the possible states a Transaction can be in.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTransactionStateTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactionStateTypes$Plain$Response(params?: ListTransactionStateTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransactionStateType>>> {
    return listTransactionStateTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible states a Transaction can be in.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTransactionStateTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactionStateTypes$Plain(params?: ListTransactionStateTypes$Plain$Params, context?: HttpContext): Observable<Array<TransactionStateType>> {
    return this.listTransactionStateTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransactionStateType>>): Array<TransactionStateType> => r.body)
    );
  }

  /**
   * Lists the possible states a Transaction can be in.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTransactionStateTypes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactionStateTypes$Json$Response(params?: ListTransactionStateTypes$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransactionStateType>>> {
    return listTransactionStateTypes$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible states a Transaction can be in.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTransactionStateTypes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactionStateTypes$Json(params?: ListTransactionStateTypes$Json$Params, context?: HttpContext): Observable<Array<TransactionStateType>> {
    return this.listTransactionStateTypes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransactionStateType>>): Array<TransactionStateType> => r.body)
    );
  }

  /** Path part for operation `listOperationTypes()` */
  static readonly ListOperationTypesPath = '/api/Enumeration/OperationTypes';

  /**
   * Lists the possible types of Operations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOperationTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOperationTypes$Plain$Response(params?: ListOperationTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OperationType>>> {
    return listOperationTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Operations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOperationTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOperationTypes$Plain(params?: ListOperationTypes$Plain$Params, context?: HttpContext): Observable<Array<OperationType>> {
    return this.listOperationTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OperationType>>): Array<OperationType> => r.body)
    );
  }

  /**
   * Lists the possible types of Operations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOperationTypes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOperationTypes$Json$Response(params?: ListOperationTypes$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OperationType>>> {
    return listOperationTypes$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Operations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOperationTypes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOperationTypes$Json(params?: ListOperationTypes$Json$Params, context?: HttpContext): Observable<Array<OperationType>> {
    return this.listOperationTypes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OperationType>>): Array<OperationType> => r.body)
    );
  }

  /** Path part for operation `listInvoiceTypes()` */
  static readonly ListInvoiceTypesPath = '/api/Enumeration/InvoiceTypes';

  /**
   * Lists the possible types of Invoices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listInvoiceTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoiceTypes$Plain$Response(params?: ListInvoiceTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InvoiceType>>> {
    return listInvoiceTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Invoices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listInvoiceTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoiceTypes$Plain(params?: ListInvoiceTypes$Plain$Params, context?: HttpContext): Observable<Array<InvoiceType>> {
    return this.listInvoiceTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InvoiceType>>): Array<InvoiceType> => r.body)
    );
  }

  /**
   * Lists the possible types of Invoices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listInvoiceTypes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoiceTypes$Json$Response(params?: ListInvoiceTypes$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InvoiceType>>> {
    return listInvoiceTypes$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Invoices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listInvoiceTypes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoiceTypes$Json(params?: ListInvoiceTypes$Json$Params, context?: HttpContext): Observable<Array<InvoiceType>> {
    return this.listInvoiceTypes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InvoiceType>>): Array<InvoiceType> => r.body)
    );
  }

  /** Path part for operation `listInvoiceStates()` */
  static readonly ListInvoiceStatesPath = '/api/Enumeration/InvoiceStates';

  /**
   * Lists the possible states of Invoices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listInvoiceStates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoiceStates$Plain$Response(params?: ListInvoiceStates$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InvoiceState>>> {
    return listInvoiceStates$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible states of Invoices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listInvoiceStates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoiceStates$Plain(params?: ListInvoiceStates$Plain$Params, context?: HttpContext): Observable<Array<InvoiceState>> {
    return this.listInvoiceStates$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InvoiceState>>): Array<InvoiceState> => r.body)
    );
  }

  /**
   * Lists the possible states of Invoices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listInvoiceStates$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoiceStates$Json$Response(params?: ListInvoiceStates$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InvoiceState>>> {
    return listInvoiceStates$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible states of Invoices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listInvoiceStates$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listInvoiceStates$Json(params?: ListInvoiceStates$Json$Params, context?: HttpContext): Observable<Array<InvoiceState>> {
    return this.listInvoiceStates$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InvoiceState>>): Array<InvoiceState> => r.body)
    );
  }

  /** Path part for operation `listTaxTypes()` */
  static readonly ListTaxTypesPath = '/api/Enumeration/TaxTypes';

  /**
   * Lists the possible types of Tax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTaxTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTaxTypes$Plain$Response(params?: ListTaxTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxType>>> {
    return listTaxTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Tax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTaxTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTaxTypes$Plain(params?: ListTaxTypes$Plain$Params, context?: HttpContext): Observable<Array<TaxType>> {
    return this.listTaxTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxType>>): Array<TaxType> => r.body)
    );
  }

  /**
   * Lists the possible types of Tax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTaxTypes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTaxTypes$Json$Response(params?: ListTaxTypes$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxType>>> {
    return listTaxTypes$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the possible types of Tax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTaxTypes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTaxTypes$Json(params?: ListTaxTypes$Json$Params, context?: HttpContext): Observable<Array<TaxType>> {
    return this.listTaxTypes$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxType>>): Array<TaxType> => r.body)
    );
  }

}
