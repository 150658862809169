import { NgModule, APP_INITIALIZER } from "@angular/core";
import { ApiConfiguration } from "src/app/modules/api-module/api-configuration";
import { ConfigLoaderService } from "src/app/services/config/config-loader.service";
import { Configuration } from "src/app/services/config/configuration";
import { ApiConfiguration as WalletApiConfiguration } from "src/app/modules/wallet-api-module/api-configuration";


@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory:
                (
                    configLoaderService: ConfigLoaderService,
                    apiConfiguration: ApiConfiguration,
                    walletApiConfiguration: WalletApiConfiguration
                ) =>
                    () => {
                        configLoaderService
                            .loadConfigurations()
                            .subscribe(
                                (config: Configuration) => {
                                    apiConfiguration.rootUrl = config.apiUrl;
                                    walletApiConfiguration.rootUrl = config.walletApiUrl;
                                });
                    },
            deps: [
                ConfigLoaderService,
                ApiConfiguration,
                WalletApiConfiguration,
            ],
            multi: true,
        },
    ],
})
export class AsyncConfigModule { }