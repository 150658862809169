<div class="mat-elevation-z8">
  <div>
    <button mat-raised-button color="primary" (click)="navigateToCreate()">
      {{ "Create Person" | transloco }}
    </button>
    <mat-divider></mat-divider>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search" />
    </mat-form-field>
  </div>
  <div *ngIf="isLoading; else showTable">
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #showTable>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortData($event)"
    >
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ "ID" | transloco }}</th>
        <td mat-cell *matCellDef="let person">{{ person.id }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Name" | transloco }}
        </th>
        <td mat-cell *matCellDef="let person">{{ person.name }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ "Email" | transloco }}</th>
        <td mat-cell *matCellDef="let person">{{ person.email }}</td>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>{{ "Phone" | transloco }}</th>
        <td mat-cell *matCellDef="let person">{{ person.phone }}</td>
      </ng-container>

      <!-- Created Date Column -->
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Created Date" | transloco }}
        </th>
        <td mat-cell *matCellDef="let person">
          {{ person.createdDate | date }}
        </td>
      </ng-container>

      <!-- Last Modified Date Column -->
      <ng-container matColumnDef="lastModifiedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Last Modified Date" | transloco }}
        </th>
        <td mat-cell *matCellDef="let person">
          {{ person.lastModifiedDate | date }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="navigateToDetails(row.id)"
      ></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [length]="totalItems"
      showFirstLastButtons
      (page)="onPageChange($event)"
    ></mat-paginator>
  </ng-template>
</div>
