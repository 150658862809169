/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createPerson } from '../fn/person-service/create-person';
import { CreatePerson$Params } from '../fn/person-service/create-person';
import { deletePerson } from '../fn/person-service/delete-person';
import { DeletePerson$Params } from '../fn/person-service/delete-person';
import { downloadAttachmentPerPerson } from '../fn/person-service/download-attachment-per-person';
import { DownloadAttachmentPerPerson$Params } from '../fn/person-service/download-attachment-per-person';
import { getPerson } from '../fn/person-service/get-person';
import { GetPerson$Params } from '../fn/person-service/get-person';
import { getPersons } from '../fn/person-service/get-persons';
import { GetPersons$Params } from '../fn/person-service/get-persons';
import { PageOfPersonDto } from '../models/page-of-person-dto';
import { PersonDto } from '../models/person-dto';
import { setPersonAttachment } from '../fn/person-service/set-person-attachment';
import { SetPersonAttachment$Params } from '../fn/person-service/set-person-attachment';
import { StoredFileDto } from '../models/stored-file-dto';
import { updatePerson } from '../fn/person-service/update-person';
import { UpdatePerson$Params } from '../fn/person-service/update-person';


/**
 * PersonService
 */
@Injectable({ providedIn: 'root' })
export class PersonService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPersons()` */
  static readonly GetPersonsPath = '/persons';

  /**
   * getPersons.
   *
   * Fetch list of persons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersons$Response(params: GetPersons$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOfPersonDto>> {
    return getPersons(this.http, this.rootUrl, params, context);
  }

  /**
   * getPersons.
   *
   * Fetch list of persons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersons(params: GetPersons$Params, context?: HttpContext): Observable<PageOfPersonDto> {
    return this.getPersons$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOfPersonDto>): PageOfPersonDto => r.body)
    );
  }

  /** Path part for operation `updatePerson()` */
  static readonly UpdatePersonPath = '/persons';

  /**
   * updatePerson.
   *
   * Update a person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePerson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePerson$Response(params: UpdatePerson$Params, context?: HttpContext): Observable<StrictHttpResponse<PersonDto>> {
    return updatePerson(this.http, this.rootUrl, params, context);
  }

  /**
   * updatePerson.
   *
   * Update a person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePerson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePerson(params: UpdatePerson$Params, context?: HttpContext): Observable<PersonDto> {
    return this.updatePerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonDto>): PersonDto => r.body)
    );
  }

  /** Path part for operation `createPerson()` */
  static readonly CreatePersonPath = '/persons';

  /**
   * createPerson.
   *
   * Create person
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPerson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPerson$Response(params: CreatePerson$Params, context?: HttpContext): Observable<StrictHttpResponse<PersonDto>> {
    return createPerson(this.http, this.rootUrl, params, context);
  }

  /**
   * createPerson.
   *
   * Create person
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPerson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPerson(params: CreatePerson$Params, context?: HttpContext): Observable<PersonDto> {
    return this.createPerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonDto>): PersonDto => r.body)
    );
  }

  /** Path part for operation `setPersonAttachment()` */
  static readonly SetPersonAttachmentPath = '/persons/{personId}/attachment';

  /**
   * Add attachment to Person. If an attachment already exists, it will be deleted.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPersonAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  setPersonAttachment$Response(params: SetPersonAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<StoredFileDto>> {
    return setPersonAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Add attachment to Person. If an attachment already exists, it will be deleted.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setPersonAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  setPersonAttachment(params: SetPersonAttachment$Params, context?: HttpContext): Observable<StoredFileDto> {
    return this.setPersonAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoredFileDto>): StoredFileDto => r.body)
    );
  }

  /** Path part for operation `getPerson()` */
  static readonly GetPersonPath = '/persons/{personId}';

  /**
   * getPerson.
   *
   * Get person by Id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerson$Response(params: GetPerson$Params, context?: HttpContext): Observable<StrictHttpResponse<PersonDto>> {
    return getPerson(this.http, this.rootUrl, params, context);
  }

  /**
   * getPerson.
   *
   * Get person by Id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerson(params: GetPerson$Params, context?: HttpContext): Observable<PersonDto> {
    return this.getPerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonDto>): PersonDto => r.body)
    );
  }

  /** Path part for operation `deletePerson()` */
  static readonly DeletePersonPath = '/persons/{personId}';

  /**
   * deletePerson.
   *
   * Delete person by ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePerson$Response(params: DeletePerson$Params, context?: HttpContext): Observable<StrictHttpResponse<PersonDto>> {
    return deletePerson(this.http, this.rootUrl, params, context);
  }

  /**
   * deletePerson.
   *
   * Delete person by ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePerson(params: DeletePerson$Params, context?: HttpContext): Observable<PersonDto> {
    return this.deletePerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonDto>): PersonDto => r.body)
    );
  }

  /** Path part for operation `downloadAttachmentPerPerson()` */
  static readonly DownloadAttachmentPerPersonPath = '/persons/{personId}/{attachmentId}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachmentPerPerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPerPerson$Response(params: DownloadAttachmentPerPerson$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return downloadAttachmentPerPerson(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachmentPerPerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachmentPerPerson(params: DownloadAttachmentPerPerson$Params, context?: HttpContext): Observable<string> {
    return this.downloadAttachmentPerPerson$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
