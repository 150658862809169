import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { Configuration } from 'src/app/services/config/configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {
  private readonly CONFIG_URL = 'assets/config/config.json';
  private configuration$!: Observable<Configuration>;

  constructor(private http: HttpClient) { }

  public loadConfigurations(): Observable<Configuration> {
    if (!this.configuration$) {
      this.configuration$ = this.http
        .get<Configuration>(this.CONFIG_URL)
        .pipe(shareReplay(1));
    }
    return this.configuration$;
  }

}