import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthInterceptor } from './auth/auth-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { ConfigLoaderService } from './services/config/config-loader.service';
import { AsyncConfigModule } from 'src/app/services/config/aync-config';
import { AuthModule } from 'src/app/auth/auth.module';

import { AppComponent } from './app.component';

import { HomeComponent } from './pages/home/home.component';
import { PersonsListComponent } from 'src/app/pages/persons-list/persons-list.component';
import { MaterialModule } from './modules/material.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PersonDetailsComponent } from './pages/person-details/person-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PersonAttachmentsComponent } from 'src/app/components/person-attachments/person-attachments.component';
import { TranslocoRootModule } from './services/transloco/transloco-root.module';
import { LanguageSwitchComponent } from 'src/app/components/language-switch/language-switch.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { LogoutComponent } from 'src/app/components/logout/logout.component';
import { LogoComponent } from 'src/app/components/logo/logo.component';
import { OrderDetailsComponent } from 'src/app/pages/order-details/order-details.component';
import { OrdersListComponent } from 'src/app/pages/orders-list/orders-list.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PersonsListComponent,
    PersonDetailsComponent,
    PersonAttachmentsComponent,
    OrdersListComponent,
    OrderDetailsComponent,
    LanguageSwitchComponent,
    LoginComponent,
    LogoutComponent,
    LogoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    AuthModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    AsyncConfigModule
  ],
  providers: [ConfigLoaderService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }