/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuthorizableTransactionReference } from '../models/authorizable-transaction-reference';
import { authorizeRefundTransaction } from '../fn/transaction/authorize-refund-transaction';
import { AuthorizeRefundTransaction$Params } from '../fn/transaction/authorize-refund-transaction';
import { cancelTransaction } from '../fn/transaction/cancel-transaction';
import { CancelTransaction$Params } from '../fn/transaction/cancel-transaction';
import { getRefundableState } from '../fn/transaction/get-refundable-state';
import { GetRefundableState$Params } from '../fn/transaction/get-refundable-state';
import { getTransactionById } from '../fn/transaction/get-transaction-by-id';
import { GetTransactionById$Params } from '../fn/transaction/get-transaction-by-id';
import { getTransactionReferenceById } from '../fn/transaction/get-transaction-reference-by-id';
import { GetTransactionReferenceById$Params } from '../fn/transaction/get-transaction-reference-by-id';
import { purchase } from '../fn/transaction/purchase';
import { Purchase$Params } from '../fn/transaction/purchase';
import { RefundableState } from '../models/refundable-state';
import { refundTransaction } from '../fn/transaction/refund-transaction';
import { RefundTransaction$Params } from '../fn/transaction/refund-transaction';
import { Transaction } from '../models/transaction';
import { TransactionReference } from '../models/transaction-reference';

@Injectable({ providedIn: 'root' })
export class TransactionService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTransactionById()` */
  static readonly GetTransactionByIdPath = '/api/Transaction/{transactionID}';

  /**
   * Get a transaction by it's ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactionById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionById$Response(params: GetTransactionById$Params, context?: HttpContext): Observable<StrictHttpResponse<Transaction>> {
    return getTransactionById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a transaction by it's ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransactionById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionById(params: GetTransactionById$Params, context?: HttpContext): Observable<Transaction> {
    return this.getTransactionById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Transaction>): Transaction => r.body)
    );
  }

  /** Path part for operation `getTransactionReferenceById()` */
  static readonly GetTransactionReferenceByIdPath = '/api/Transaction/{transactionID}/authorizablereference';

  /**
   * Get an authorizabletransactionreference by the transanction's ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactionReferenceById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionReferenceById$Response(params: GetTransactionReferenceById$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthorizableTransactionReference>> {
    return getTransactionReferenceById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an authorizabletransactionreference by the transanction's ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransactionReferenceById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionReferenceById(params: GetTransactionReferenceById$Params, context?: HttpContext): Observable<AuthorizableTransactionReference> {
    return this.getTransactionReferenceById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthorizableTransactionReference>): AuthorizableTransactionReference => r.body)
    );
  }

  /** Path part for operation `purchase()` */
  static readonly PurchasePath = '/api/Transaction/purchase';

  /**
   * Starts an In-App purchase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `purchase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  purchase$Response(params?: Purchase$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthorizableTransactionReference>> {
    return purchase(this.http, this.rootUrl, params, context);
  }

  /**
   * Starts an In-App purchase.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `purchase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  purchase(params?: Purchase$Params, context?: HttpContext): Observable<AuthorizableTransactionReference> {
    return this.purchase$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthorizableTransactionReference>): AuthorizableTransactionReference => r.body)
    );
  }

  /** Path part for operation `getRefundableState()` */
  static readonly GetRefundableStatePath = '/api/Transaction/{transactionID}/refundable';

  /**
   * Checks wether the transaction as identified by it's id can be refunded.
   *
   * This will evaluate both the permissions of the current uset/tenant and it's relation to the transaction, as well as the current transaction state: is it a refundable type, has it already been refunded?
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRefundableState()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRefundableState$Response(params: GetRefundableState$Params, context?: HttpContext): Observable<StrictHttpResponse<RefundableState>> {
    return getRefundableState(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks wether the transaction as identified by it's id can be refunded.
   *
   * This will evaluate both the permissions of the current uset/tenant and it's relation to the transaction, as well as the current transaction state: is it a refundable type, has it already been refunded?
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRefundableState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRefundableState(params: GetRefundableState$Params, context?: HttpContext): Observable<RefundableState> {
    return this.getRefundableState$Response(params, context).pipe(
      map((r: StrictHttpResponse<RefundableState>): RefundableState => r.body)
    );
  }

  /** Path part for operation `refundTransaction()` */
  static readonly RefundTransactionPath = '/api/Transaction/{transactionID}/refund';

  /**
   * Refunds a COMPLETED transaction by creating a new transaction reversing all original transaction operations.
   *
   * This can only be done one refundable transactiontypes. (typically all kinds of purchases).
   * Permission wise, Vendors (resellers) can refund their transactions.
   * Publishers can refund their own (direct) purchase transactions and the (indirect) purchase transactions from all their resellers.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refundTransaction()` instead.
   *
   * This method doesn't expect any request body.
   */
  refundTransaction$Response(params: RefundTransaction$Params, context?: HttpContext): Observable<StrictHttpResponse<TransactionReference>> {
    return refundTransaction(this.http, this.rootUrl, params, context);
  }

  /**
   * Refunds a COMPLETED transaction by creating a new transaction reversing all original transaction operations.
   *
   * This can only be done one refundable transactiontypes. (typically all kinds of purchases).
   * Permission wise, Vendors (resellers) can refund their transactions.
   * Publishers can refund their own (direct) purchase transactions and the (indirect) purchase transactions from all their resellers.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refundTransaction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refundTransaction(params: RefundTransaction$Params, context?: HttpContext): Observable<TransactionReference> {
    return this.refundTransaction$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransactionReference>): TransactionReference => r.body)
    );
  }

  /** Path part for operation `authorizeRefundTransaction()` */
  static readonly AuthorizeRefundTransactionPath = '/api/Transaction/{transactionID}/authorize-refund';

  /**
   * Authorizes a refund transaction. This will complete the Transaction (changing transaction state from UNAUTHORIZED to COMPLETED).
   *
   * This can only be called in context of the application itself.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizeRefundTransaction()` instead.
   *
   * This method doesn't expect any request body.
   */
  authorizeRefundTransaction$Response(params: AuthorizeRefundTransaction$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authorizeRefundTransaction(this.http, this.rootUrl, params, context);
  }

  /**
   * Authorizes a refund transaction. This will complete the Transaction (changing transaction state from UNAUTHORIZED to COMPLETED).
   *
   * This can only be called in context of the application itself.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizeRefundTransaction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authorizeRefundTransaction(params: AuthorizeRefundTransaction$Params, context?: HttpContext): Observable<void> {
    return this.authorizeRefundTransaction$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cancelTransaction()` */
  static readonly CancelTransactionPath = '/api/Transaction/{transactionID}/cancel';

  /**
   * Cancels a transaction.
   *
   * Cancels a Transactions that is not in a finalized state. (Typically DRAFT, UNAUTHORIZED).
   * Can be called in a User-Context: (User from same tenant can cancel a transaction from the current application ) or in an Application Context (Client-Credentials Authentication) to cancel any Transaction belonging to the application.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelTransaction()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelTransaction$Response(params: CancelTransaction$Params, context?: HttpContext): Observable<StrictHttpResponse<TransactionReference>> {
    return cancelTransaction(this.http, this.rootUrl, params, context);
  }

  /**
   * Cancels a transaction.
   *
   * Cancels a Transactions that is not in a finalized state. (Typically DRAFT, UNAUTHORIZED).
   * Can be called in a User-Context: (User from same tenant can cancel a transaction from the current application ) or in an Application Context (Client-Credentials Authentication) to cancel any Transaction belonging to the application.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelTransaction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelTransaction(params: CancelTransaction$Params, context?: HttpContext): Observable<TransactionReference> {
    return this.cancelTransaction$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransactionReference>): TransactionReference => r.body)
    );
  }

}
