<div class="container">
  <div *ngIf="isLoading; else showForm">
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #showForm>
    <form [formGroup]="personForm">
      <mat-form-field appearance="fill">
        <mat-label>{{ "Name" | transloco }}</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "Email" | transloco }}</mat-label>
        <input matInput formControlName="email" required />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "Phone" | transloco }}</mat-label>
        <input matInput formControlName="phone" required />
      </mat-form-field>

      <button mat-raised-button type="submit" (click)="onSave()">Save</button>

      <ng-container *ngIf="mode === 'edit'">
        <button mat-stroked-button color="primary" (click)="sendEmail()">
          {{ "Send email" | transloco }}
        </button>

        <div class="file-upload-container">
          <button mat-raised-button color="primary" (click)="fileInput.click()">
            {{ "Add Files" | transloco }}
          </button>
          <input
            #fileInput
            type="file"
            (change)="onFilesSelected($event)"
            multiple
            style="display: none"
          />
        </div>
        <mat-divider></mat-divider>

        <div class="file-list-container">
          <!-- Display the list of files using mat-chip-list -->
          <mat-chip-listbox>
            <mat-chip
              *ngFor="let file of selectedFiles; let i = index"
              [removable]="true"
              (removed)="removeFile(file)"
              class="mat-elevation-z2"
              appearance="outline"
            >
              {{ file.name }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>
        </div>

        <mat-divider></mat-divider>

        <button
          mat-raised-button
          color="primary"
          (click)="addAttachmentsToPerson()"
        >
          {{ "Upload Files" | transloco }}
        </button>
        <mat-divider></mat-divider>
        <app-person-attachments
          [attachments]="personForm.value.attachments"
          [personId]="personId"
        ></app-person-attachments>
        <mat-divider></mat-divider>
        <button mat-raised-button color="warn" (click)="deletePerson()">
          {{ "Delete" | transloco }}
        </button>
      </ng-container>
    </form>
  </ng-template>
</div>
