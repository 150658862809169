import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, of } from 'rxjs';
import { OrderDto, PaymentState, PurchaseType } from 'src/app/modules/api-module/models';
import { OrderService } from 'src/app/modules/api-module/services';
import { Purchase } from 'src/app/modules/wallet-api-module/models';
import { TransactionService } from 'src/app/modules/wallet-api-module/services';
import { ConfigLoaderService } from 'src/app/services/config/config-loader.service';
import { Configuration } from 'src/app/services/config/configuration';
import { DatePipe } from '@angular/common';
import { GetOrder$Params } from 'src/app/modules/api-module/fn/order-service/get-order';
import { CreateOrder$Params } from 'src/app/modules/api-module/fn/order-service/create-order';
import { UpdateOrder$Params } from 'src/app/modules/api-module/fn/order-service/update-order';
import { DeleteOrder$Params } from 'src/app/modules/api-module/fn/order-service/delete-order';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css'],
  providers: [DatePipe]
})
export class OrderDetailsComponent implements OnInit {
  isLoading: boolean = false;
  config!: Configuration;
  errorMessage: string = "";
  orderForm!: FormGroup;
  orderId: string | null = null;
  mode!: string;

  paymentStates: Array<PaymentState> = [PaymentState.Cancelled, PaymentState.Pending, PaymentState.Failed, PaymentState.Completed];
  purchaseTypes: Array<PurchaseType> = [PurchaseType.InAppPurchase, PurchaseType.ApplicationSubscription, PurchaseType.ExtensionSubscription];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private transactionService: TransactionService,
    private configLoaderService: ConfigLoaderService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.createForm();

    this.route.data.subscribe((data) => {
      this.mode = data['mode'];
    });

    this.route.paramMap.subscribe(params => {
      this.orderId = params.get('orderId');
      if (this.mode === 'edit' && this.orderId) {
        this.getOrder();
      }
    });

    this.configLoaderService.loadConfigurations().subscribe(
      data => {
        this.config = data;
      },
      error => {
        console.error('Error fetching config:', error);
      }
    );
  }

  getOrder() {
    const payload: GetOrder$Params = {
      orderId: this.orderId!
    };
    this.isLoading = true;
    this.orderService.getOrder(payload).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (data: OrderDto) => {

        const formattedCreatedDate = this.datePipe.transform(data.createdDate, 'yyyy-MM-dd');
        const formattedLastModifiedDate = this.datePipe.transform(data.lastModifiedDate, 'yyyy-MM-dd');

        this.orderForm.patchValue({
          ...data,
          createdDate: formattedCreatedDate,
          lastModifiedDate: formattedLastModifiedDate
        });
      },
      (error: string) => {
        this.errorMessage = error;
        console.error('Error fetching a order: ', error);
      }
    );
  }

  createForm() {
    this.orderForm = this.fb.group({
      orderReference: [''],
      transactionReferenceId: [''],
      productName: ['', Validators.required],
      netAmount: ['', [Validators.required]],
      state: [PaymentState.Pending],
      type: [PurchaseType.ApplicationSubscription],
      number: [''],
      createdDate: [''],
      lastModifiedDate: ['']
    });
  }

  onSave(): void {
    if (this.orderForm.valid) {
      if (this.mode === 'edit') {
        this.updateOrder();
      } else {
        this.createOrder();
      }
    }
  }

  createOrder() {
    this.isLoading = true;
    const payload: CreateOrder$Params = {
      body: this.orderForm.value
    };
    this.orderService.createOrder(payload).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (data: OrderDto) => {
        this.router.navigate(['/orders']);
      },
      (error: string) => {
        this.errorMessage = error;
        console.error('Error creating a order: ', error);
      }
    );
  }

  updateOrder() {
    this.isLoading = true;
    const payload: UpdateOrder$Params = {
      body: this.orderForm.value
    };
    this.orderService.updateOrder(payload).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (data: OrderDto) => {
        this.router.navigate(['/orders']);
      },
      (error: string) => {
        this.errorMessage = error;
        console.error('Error updating a order: ', error);
      }
    );
  }

  deleteOrder(): void {
    this.isLoading = true;
    const payload: DeleteOrder$Params = {
      orderId: this.orderId!
    };
    this.orderService.deleteOrder(payload).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (data: OrderDto) => {
        this.router.navigate(['/orders']);
      },
      (error: string) => {
        this.errorMessage = error;
        console.error('Error deleting a order: ', error);
      }
    );
  }

  doPayment(): void {
    this.isLoading = true;
    const payload: Purchase = {
      netAmount: this.orderForm.controls['netAmount'].value,
      orderReference: this.orderForm.controls['orderReference'].value,
      productName: this.orderForm.controls['productName'].value,
      beneficiaries: []
    };
    this.transactionService.purchase({ body: payload }).pipe(
      catchError(error => {
        console.error('Purchase failed:', error);
        this.isLoading = false;
        return of(null);
      })
    ).subscribe(response => {
      if (response) {
        window.location.href = response.authorizeUrl + `?returnUrl=` + this.config?.redirectUri + `/orders`;
      }
      // this.isLoading = false;
    });
  }
}
