<div>
  <mat-form-field appearance="fill">
    <mat-label>{{ "Language" | transloco }}</mat-label>
    <mat-select
      [(value)]="selectedLanguage"
      (selectionChange)="onLanguageChange($event.value)"
    >
      @for (lang of languages; track lang) {
      <mat-option [value]="lang">{{ lang }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
