/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuthorizableTransactionReference } from '../models/authorizable-transaction-reference';
import { getTransactionGroupRefundableState } from '../fn/transaction-group/get-transaction-group-refundable-state';
import { GetTransactionGroupRefundableState$Params } from '../fn/transaction-group/get-transaction-group-refundable-state';
import { GroupRefundableState } from '../models/group-refundable-state';
import { refundToWallet } from '../fn/transaction-group/refund-to-wallet';
import { RefundToWallet$Params } from '../fn/transaction-group/refund-to-wallet';
import { transactionGroupPurchase } from '../fn/transaction-group/transaction-group-purchase';
import { TransactionGroupPurchase$Params } from '../fn/transaction-group/transaction-group-purchase';
import { TransactionReference } from '../models/transaction-reference';

@Injectable({ providedIn: 'root' })
export class TransactionGroupService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `transactionGroupPurchase()` */
  static readonly TransactionGroupPurchasePath = '/api/TransactionGroup/{groupID}/purchase';

  /**
   * Extends a Group/Root Transaction with an additional Purchase.
   *
   * This will re-use all settings from the original (root) Transaction, Even if Marketplace conditions for Beneficiaries and the like may have changed. It it the only way to manage some form of consistent refunds.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionGroupPurchase()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionGroupPurchase$Response(params: TransactionGroupPurchase$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthorizableTransactionReference>> {
    return transactionGroupPurchase(this.http, this.rootUrl, params, context);
  }

  /**
   * Extends a Group/Root Transaction with an additional Purchase.
   *
   * This will re-use all settings from the original (root) Transaction, Even if Marketplace conditions for Beneficiaries and the like may have changed. It it the only way to manage some form of consistent refunds.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionGroupPurchase$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionGroupPurchase(params: TransactionGroupPurchase$Params, context?: HttpContext): Observable<AuthorizableTransactionReference> {
    return this.transactionGroupPurchase$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthorizableTransactionReference>): AuthorizableTransactionReference => r.body)
    );
  }

  /** Path part for operation `refundToWallet()` */
  static readonly RefundToWalletPath = '/api/TransactionGroup/{groupID}/wallet-refund';

  /**
   * Partially refund a requested  Amount from a TransactionGroup.
   *
   * When called using an ApplicationContext (client-credentials login), the refund will be completed immediatly.
   * When called using a User/Tenant Context, the refund will be created in an UNAUTHORIZED state, and needs to be authorized.
   * If this refund will initialize the group 
   * <list type="bullet"><item><description>the group's root transaction MUST be an IN_APP_PURCHASE.</description></item><item><description>the group's root transaction MUST be in a COMPLETED state.</description></item><item><description>the group's root transaction MUST NOT have any non group refunds.</description></item></list>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refundToWallet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refundToWallet$Response(params: RefundToWallet$Params, context?: HttpContext): Observable<StrictHttpResponse<TransactionReference>> {
    return refundToWallet(this.http, this.rootUrl, params, context);
  }

  /**
   * Partially refund a requested  Amount from a TransactionGroup.
   *
   * When called using an ApplicationContext (client-credentials login), the refund will be completed immediatly.
   * When called using a User/Tenant Context, the refund will be created in an UNAUTHORIZED state, and needs to be authorized.
   * If this refund will initialize the group 
   * <list type="bullet"><item><description>the group's root transaction MUST be an IN_APP_PURCHASE.</description></item><item><description>the group's root transaction MUST be in a COMPLETED state.</description></item><item><description>the group's root transaction MUST NOT have any non group refunds.</description></item></list>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refundToWallet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refundToWallet(params: RefundToWallet$Params, context?: HttpContext): Observable<TransactionReference> {
    return this.refundToWallet$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransactionReference>): TransactionReference => r.body)
    );
  }

  /** Path part for operation `getTransactionGroupRefundableState()` */
  static readonly GetTransactionGroupRefundableStatePath = '/api/TransactionGroup/{groupID}/refundable';

  /**
   * Reports the amounts that are and can still be refunded for the given transaction group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransactionGroupRefundableState()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionGroupRefundableState$Response(params: GetTransactionGroupRefundableState$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupRefundableState>> {
    return getTransactionGroupRefundableState(this.http, this.rootUrl, params, context);
  }

  /**
   * Reports the amounts that are and can still be refunded for the given transaction group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransactionGroupRefundableState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransactionGroupRefundableState(params: GetTransactionGroupRefundableState$Params, context?: HttpContext): Observable<GroupRefundableState> {
    return this.getTransactionGroupRefundableState$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupRefundableState>): GroupRefundableState => r.body)
    );
  }

}
