import { Component, AfterViewInit, Input, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DownloadAttachmentPerPerson$Params } from "src/app/modules/api-module/fn/person-service/download-attachment-per-person";
import { StoredFileDto } from "src/app/modules/api-module/models";
import { PersonService } from "src/app/modules/api-module/services";

@Component({
  selector: 'app-person-attachments',
  templateUrl: './person-attachments.component.html',
  styleUrl: './person-attachments.component.css'
})
export class PersonAttachmentsComponent implements AfterViewInit {
  @Input() set attachments(data: StoredFileDto[]) {
    this.dataSource = new MatTableDataSource(data);
    this.totalItems = data.length;
    if (this.sort && this.paginator) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
  @Input() personId: string | null = null;

  displayedColumns: string[] = ['fileName', 'fileType'];
  pageSizeOptions: number[] = [1, 2, 5, 10, 20, 50];
  dataSource = new MatTableDataSource<StoredFileDto>();
  isLoading: boolean = false;
  errorMessage: string = "";
  searchName: string = "";
  totalItems: number = 0;
  pageSize: number = 5;
  pageIndex: number = 0;

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private personService: PersonService) { }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  sortData(sort: Sort) {
    this.dataSource.sort!.direction = sort.direction;
    this.dataSource.sort!.active = sort.active;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  downloadAttachment(attachmentId: string, fileName: string) {
    const payload: DownloadAttachmentPerPerson$Params = {
      personId: this.personId!,
      attachmentId: attachmentId
    }
    this.personService.downloadAttachmentPerPerson(payload).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    })
  }
}
