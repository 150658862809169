import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { PageOfPersonDto, PersonDto } from 'src/app/modules/api-module/models';
import { PersonService } from 'src/app/modules/api-module/services';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { GetPersons$Params } from 'src/app/modules/api-module/fn/person-service/get-persons';

@Component({
  selector: 'app-persons-list',
  templateUrl: './persons-list.component.html',
  styleUrls: ['./persons-list.component.css'],
  // imports: [MatTableModule, MatSortModule],
})
export class PersonsListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id', 'name', 'email', 'phone', 'createdDate', 'lastModifiedDate'];
  pageSizeOptions: number[] = [1, 2, 5, 10, 20, 50];
  dataSource = new MatTableDataSource<PersonDto>();
  isLoading: boolean = false;
  errorMessage: string = "";
  searchName: string = "";
  totalItems: number = 0;
  pageSize: number = 5;
  pageIndex: number = 0;

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private personService: PersonService, private router: Router) { }

  ngOnInit() {
    this.getPersons();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  sortData(sort: Sort) {
    this.dataSource.sort!.direction = sort.direction;
    this.dataSource.sort!.active = sort.active;
    this.getPersons();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getPersons();
  }

  getPersons() {
    this.isLoading = true;
    const sortDirection = this.sort?.direction || 'asc';
    const sortActive = this.sort?.active || 'name';

    const payload: GetPersons$Params = {
      name: this.searchName,
      pageable: {
        page: this.pageIndex,
        size: this.pageSize,
        sort: [sortActive, sortDirection]
      }
    };

    this.personService.getPersons(payload).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (data: PageOfPersonDto) => {
        this.dataSource = new MatTableDataSource(data.content);
        this.totalItems = data.totalElements!;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error: string) => {
        this.errorMessage = error;
        console.error('Error fetching persons: ', error);
      }
    );
  }

  navigateToDetails(personId: string) {
    this.router.navigate(['/person', personId]);
  }

  navigateToCreate() {
    this.router.navigate(['/person']);
  }
}
