/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RefundableState } from '../../models/refundable-state';

export interface GetRefundableState$Params {

/**
 * The unique id of the transaction
 */
  transactionID: string;
}

export function getRefundableState(http: HttpClient, rootUrl: string, params: GetRefundableState$Params, context?: HttpContext): Observable<StrictHttpResponse<RefundableState>> {
  const rb = new RequestBuilder(rootUrl, getRefundableState.PATH, 'get');
  if (params) {
    rb.path('transactionID', params.transactionID, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RefundableState>;
    })
  );
}

getRefundableState.PATH = '/api/Transaction/{transactionID}/refundable';
