/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PersonDto } from '../models/person-dto';
import { receiveNotification } from '../fn/payment-notification-service/receive-notification';
import { ReceiveNotification$Params } from '../fn/payment-notification-service/receive-notification';


/**
 * PaymentNotificationService
 */
@Injectable({ providedIn: 'root' })
export class PaymentNotificationService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `receiveNotification()` */
  static readonly ReceiveNotificationPath = '/payments/notifications';

  /**
   * paymentNotification.
   *
   * Update payment status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receiveNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receiveNotification$Response(params: ReceiveNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<PersonDto>> {
    return receiveNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * paymentNotification.
   *
   * Update payment status
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receiveNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receiveNotification(params: ReceiveNotification$Params, context?: HttpContext): Observable<PersonDto> {
    return this.receiveNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonDto>): PersonDto => r.body)
    );
  }

}
