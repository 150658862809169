import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrl: './language-switch.component.css'
})
export class LanguageSwitchComponent implements OnInit {

  languages: string[] = [];
  selectedLanguage!: string;

  constructor(private translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.languages = this.translocoService.getAvailableLangs() as string[];
    this.selectedLanguage = this.translocoService.getActiveLang();
  }

  onLanguageChange(lang: string): void {
    this.translocoService.setActiveLang(lang);
  }

}
