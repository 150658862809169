/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GroupRefund } from '../../models/group-refund';
import { TransactionReference } from '../../models/transaction-reference';

export interface RefundToWallet$Params {
  groupID: string;
      body?: GroupRefund
}

export function refundToWallet(http: HttpClient, rootUrl: string, params: RefundToWallet$Params, context?: HttpContext): Observable<StrictHttpResponse<TransactionReference>> {
  const rb = new RequestBuilder(rootUrl, refundToWallet.PATH, 'post');
  if (params) {
    rb.path('groupID', params.groupID, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TransactionReference>;
    })
  );
}

refundToWallet.PATH = '/api/TransactionGroup/{groupID}/wallet-refund';
