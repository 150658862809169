import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard';
import { HomeComponent } from './pages/home/home.component';
import { PersonsListComponent } from 'src/app/pages/persons-list/persons-list.component';
import { PersonDetailsComponent } from 'src/app/pages/person-details/person-details.component';
import { OrderDetailsComponent } from 'src/app/pages/order-details/order-details.component';
import { OrdersListComponent } from 'src/app/pages/orders-list/orders-list.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'person/:personId', component: PersonDetailsComponent, data: { mode: 'edit' }, canActivate: [AuthGuard] },
  { path: 'person', component: PersonDetailsComponent, data: { mode: 'create' }, canActivate: [AuthGuard] },
  { path: 'persons', component: PersonsListComponent, canActivate: [AuthGuard] },
  { path: 'order/:orderId', component: OrderDetailsComponent, data: { mode: 'edit' }, canActivate: [AuthGuard] },
  { path: 'order', component: OrderDetailsComponent, data: { mode: 'create' }, canActivate: [AuthGuard] },
  { path: 'orders', component: OrdersListComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
