import { Component } from '@angular/core';
import { ConfigLoaderService } from 'src/app/services/config/config-loader.service';
import { Configuration } from 'src/app/services/config/configuration';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  config!: Configuration;

  constructor(private configLoaderService: ConfigLoaderService) {
    this.configLoaderService.loadConfigurations().subscribe(
      data => {
        this.config = data;
      },
      error => {
        console.error('Error fetching config:', error)
      }
    );
  }
}
