/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTenant } from '../fn/tenant-service/create-tenant';
import { CreateTenant$Params } from '../fn/tenant-service/create-tenant';
import { deleteTenant } from '../fn/tenant-service/delete-tenant';
import { DeleteTenant$Params } from '../fn/tenant-service/delete-tenant';
import { getTenant } from '../fn/tenant-service/get-tenant';
import { GetTenant$Params } from '../fn/tenant-service/get-tenant';
import { getTenants } from '../fn/tenant-service/get-tenants';
import { GetTenants$Params } from '../fn/tenant-service/get-tenants';
import { PageOfTenantDto } from '../models/page-of-tenant-dto';
import { TenantDto } from '../models/tenant-dto';
import { updateTenant } from '../fn/tenant-service/update-tenant';
import { UpdateTenant$Params } from '../fn/tenant-service/update-tenant';


/**
 * TenantService
 */
@Injectable({ providedIn: 'root' })
export class TenantService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTenants()` */
  static readonly GetTenantsPath = '/tenants';

  /**
   * getTenants.
   *
   * Fetch list of Tenants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants$Response(params: GetTenants$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOfTenantDto>> {
    return getTenants(this.http, this.rootUrl, params, context);
  }

  /**
   * getTenants.
   *
   * Fetch list of Tenants
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants(params: GetTenants$Params, context?: HttpContext): Observable<PageOfTenantDto> {
    return this.getTenants$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOfTenantDto>): PageOfTenantDto => r.body)
    );
  }

  /** Path part for operation `updateTenant()` */
  static readonly UpdateTenantPath = '/tenants';

  /**
   * updateTenant.
   *
   * Update a Tenant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenant$Response(params: UpdateTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantDto>> {
    return updateTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * updateTenant.
   *
   * Update a Tenant
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenant(params: UpdateTenant$Params, context?: HttpContext): Observable<TenantDto> {
    return this.updateTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantDto>): TenantDto => r.body)
    );
  }

  /** Path part for operation `createTenant()` */
  static readonly CreateTenantPath = '/tenants';

  /**
   * createTenant.
   *
   * Create Tenant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant$Response(params: CreateTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantDto>> {
    return createTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * createTenant.
   *
   * Create Tenant
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant(params: CreateTenant$Params, context?: HttpContext): Observable<TenantDto> {
    return this.createTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantDto>): TenantDto => r.body)
    );
  }

  /** Path part for operation `getTenant()` */
  static readonly GetTenantPath = '/tenants/{tenantId}';

  /**
   * getTenant.
   *
   * Get Tenant by Id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenant$Response(params: GetTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantDto>> {
    return getTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * getTenant.
   *
   * Get Tenant by Id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenant(params: GetTenant$Params, context?: HttpContext): Observable<TenantDto> {
    return this.getTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantDto>): TenantDto => r.body)
    );
  }

  /** Path part for operation `deleteTenant()` */
  static readonly DeleteTenantPath = '/tenants/{tenantId}';

  /**
   * deleteTenant.
   *
   * Delete Tenant by ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant$Response(params: DeleteTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantDto>> {
    return deleteTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * deleteTenant.
   *
   * Delete Tenant by ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant(params: DeleteTenant$Params, context?: HttpContext): Observable<TenantDto> {
    return this.deleteTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantDto>): TenantDto => r.body)
    );
  }

}
