import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatChipEditedEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SentEmail$Params } from 'src/app/modules/api-module/fn/email-service/sent-email';
import { CreatePerson$Params } from 'src/app/modules/api-module/fn/person-service/create-person';
import { DeletePerson$Params } from 'src/app/modules/api-module/fn/person-service/delete-person';
import { GetPerson$Params } from 'src/app/modules/api-module/fn/person-service/get-person';
import { SetPersonAttachment$Params } from 'src/app/modules/api-module/fn/person-service/set-person-attachment';
import { UpdatePerson$Params } from 'src/app/modules/api-module/fn/person-service/update-person';
import { PersonDto, StoredFileDto } from 'src/app/modules/api-module/models';
import { EmailService, PersonService } from 'src/app/modules/api-module/services';

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.css'],
})
export class PersonDetailsComponent implements OnInit {
  isLoading: boolean = false;
  errorMessage: string = '';
  personForm!: FormGroup;
  personId: string | null = null;
  mode!: string;
  selectedFiles: File[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private personService: PersonService,
    private emailService: EmailService
  ) { }

  ngOnInit(): void {
    this.createForm();

    this.route.data.subscribe((data) => {
      this.mode = data['mode'];
    });

    this.route.paramMap.subscribe((params) => {
      this.personId = params.get('personId');
      if (this.mode === 'edit' && this.personId) {
        this.getPerson();
      }
    });
  }

  getPerson() {
    const payload: GetPerson$Params = {
      personId: this.personId!,
    };
    this.personService
      .getPerson(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: PersonDto) => {
          this.personForm.patchValue(data);
          data.attachments?.forEach(attachment => {
            this.attachments.value.push(attachment)
          })
        },
        (error: string) => {
          this.errorMessage = error;
          console.error('Error fetching a person: ', error);
        }
      );
  }

  createForm() {
    this.personForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      createdDate: [''],
      lastModifiedDate: [''],
      attachments: this.fb.array([]),
    });
  }

  onSave(): void {
    if (this.personForm.valid) {
      if (this.mode === 'edit') {
        this.updatePerson();
      } else {
        this.createPerson();
      }
    }
  }

  createPerson() {
    this.isLoading = true;
    const payload: CreatePerson$Params = {
      body: this.personForm.value,
    };
    this.personService
      .createPerson(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: PersonDto) => {
          this.router.navigate(['/persons']);
        },
        (error: string) => {
          this.errorMessage = error;
          console.error('Error creating a person: ', error);
        }
      );
  }

  updatePerson() {
    this.isLoading = true;
    const payload: UpdatePerson$Params = {
      body: this.personForm.value,
    };
    this.personService
      .updatePerson(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: PersonDto) => {
          this.router.navigate(['/persons']);
        },
        (error: string) => {
          this.errorMessage = error;
          console.error('Error updating a person: ', error);
        }
      );
  }

  deletePerson(): void {
    this.isLoading = true;
    const payload: DeletePerson$Params = {
      personId: this.personId!,
    };
    this.personService
      .deletePerson(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: PersonDto) => {
          this.router.navigate(['/persons']);
        },
        (error: string) => {
          this.errorMessage = error;
          console.error('Error deleting a person: ', error);
        }
      );
  }

  sendEmail(): void {
    this.isLoading = true;
    const payload: SentEmail$Params = {
      body: this.personForm.value
    };
    this.emailService.sentEmail(payload).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(response => {
      console.log(response)
    },
      (error: string) => {
        this.errorMessage = error;
        console.error('Error sending and email to a person: ', error);
      }
    );
  }

  onFilesSelected(event: any): void {
    if (event.target.files) {
      this.selectedFiles = Array.from(event.target.files);
    }
  }

  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);

    if (index >= 0) {
      this.selectedFiles.splice(index, 1);
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
    }
  }

  editFile(attachment: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.removeFile(attachment);
      return;
    }


    const index = this.attachments.value.indexOf(attachment);
    if (index >= 0) {
      this.attachments.value[index].name = value;
    }
  }


  addAttachmentsToPerson() {
    if (!this.selectedFiles.length || !this.personId) {
      this.errorMessage = 'No files selected or person ID missing.';
      return;
    }

    this.isLoading = true;

    const requests = this.selectedFiles.map((file: File) => {
      const payload: SetPersonAttachment$Params = {
        personId: this.personId!,
        body: { file: file },
      };
      this.removeFile(file);

      return this.personService.setPersonAttachment(payload);
    });

    requests.forEach((request) => {
      request
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (data: StoredFileDto) => {
            this.addAttachment(data);
            this.errorMessage = '';
          },
          (error: string) => {
            this.errorMessage = error;
            console.error('Error uploading a file to a person: ', error);
          }
        );
    });
  }

  get attachments(): FormArray {
    return this.personForm.get('attachments') as FormArray;
  }

  private addAttachment(newAttachment: StoredFileDto): void {
    const attachmentControl = new FormControl(newAttachment);
    this.attachments.push(attachmentControl);
  }
}
