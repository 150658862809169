<div class="mat-elevation-z8">
  <div>
    <button mat-raised-button color="primary" (click)="navigateToCreate()">
      {{ "Create Order" | transloco }}
    </button>
    <mat-divider></mat-divider>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search" />
    </mat-form-field>
  </div>
  <div *ngIf="isLoading; else showTable">
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #showTable>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortData($event)"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Product Name" | transloco }}
        </th>
        <td mat-cell *matCellDef="let order">{{ order.productName }}</td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="netAmount">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Net Amount" | transloco }}
        </th>
        <td mat-cell *matCellDef="let order">{{ order.netAmount }}</td>
      </ng-container>

      <!-- Payment State Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Payment State" | transloco }}
        </th>
        <td mat-cell *matCellDef="let order">{{ order.state }}</td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Payment Type" | transloco }}
        </th>
        <td mat-cell *matCellDef="let order">{{ order.type }}</td>
      </ng-container>

      <!-- Number Column -->
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>{{ "Number" | transloco }}</th>
        <td mat-cell *matCellDef="let order">{{ order.number }}</td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="orderReference">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Order Reference ID" | transloco }}
        </th>
        <td mat-cell *matCellDef="let order">{{ order.orderReference }}</td>
      </ng-container>

      <!-- Transaction Reference ID Column -->
      <ng-container matColumnDef="transactionReferenceId">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Transaction Reference ID" | transloco }}
        </th>
        <td mat-cell *matCellDef="let order">
          {{ order.transactionReferenceId }}
        </td>
      </ng-container>

      <!-- Created Date Column -->
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Created Date" | transloco }}
        </th>
        <td mat-cell *matCellDef="let order">
          {{ order.createdDate | date }}
        </td>
      </ng-container>

      <!-- Last Modified Date Column -->
      <ng-container matColumnDef="lastModifiedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "Last Modified Date" | transloco }}
        </th>
        <td mat-cell *matCellDef="let order">
          {{ order.lastModifiedDate | date }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="navigateToDetails(row.orderReference)"
      ></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [length]="totalItems"
      showFirstLastButtons
      (page)="onPageChange($event)"
    ></mat-paginator>
  </ng-template>
</div>
