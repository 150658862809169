/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AuthorizableTransactionReference } from '../../models/authorizable-transaction-reference';

export interface GetTransactionReferenceById$Params {

/**
 * The unique id of the transaction
 */
  transactionID: string;
}

export function getTransactionReferenceById(http: HttpClient, rootUrl: string, params: GetTransactionReferenceById$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthorizableTransactionReference>> {
  const rb = new RequestBuilder(rootUrl, getTransactionReferenceById.PATH, 'get');
  if (params) {
    rb.path('transactionID', params.transactionID, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AuthorizableTransactionReference>;
    })
  );
}

getTransactionReferenceById.PATH = '/api/Transaction/{transactionID}/authorizablereference';
