/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { listWalletTransactionHistory$Json } from '../fn/transaction-history/list-wallet-transaction-history-json';
import { ListWalletTransactionHistory$Json$Params } from '../fn/transaction-history/list-wallet-transaction-history-json';
import { listWalletTransactionHistory$Plain } from '../fn/transaction-history/list-wallet-transaction-history-plain';
import { ListWalletTransactionHistory$Plain$Params } from '../fn/transaction-history/list-wallet-transaction-history-plain';
import { WalletTransactionHistory } from '../models/wallet-transaction-history';

@Injectable({ providedIn: 'root' })
export class TransactionHistoryService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listWalletTransactionHistory()` */
  static readonly ListWalletTransactionHistoryPath = '/api/TransactionHistory/{walletID}';

  /**
   * Lists the history of Transactions on a single Wallet (paged).
   *
   * Long note here
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWalletTransactionHistory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletTransactionHistory$Plain$Response(params: ListWalletTransactionHistory$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WalletTransactionHistory>>> {
    return listWalletTransactionHistory$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the history of Transactions on a single Wallet (paged).
   *
   * Long note here
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listWalletTransactionHistory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletTransactionHistory$Plain(params: ListWalletTransactionHistory$Plain$Params, context?: HttpContext): Observable<Array<WalletTransactionHistory>> {
    return this.listWalletTransactionHistory$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WalletTransactionHistory>>): Array<WalletTransactionHistory> => r.body)
    );
  }

  /**
   * Lists the history of Transactions on a single Wallet (paged).
   *
   * Long note here
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWalletTransactionHistory$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletTransactionHistory$Json$Response(params: ListWalletTransactionHistory$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WalletTransactionHistory>>> {
    return listWalletTransactionHistory$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists the history of Transactions on a single Wallet (paged).
   *
   * Long note here
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listWalletTransactionHistory$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletTransactionHistory$Json(params: ListWalletTransactionHistory$Json$Params, context?: HttpContext): Observable<Array<WalletTransactionHistory>> {
    return this.listWalletTransactionHistory$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WalletTransactionHistory>>): Array<WalletTransactionHistory> => r.body)
    );
  }

}
