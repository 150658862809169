import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { GetOrder$Params } from 'src/app/modules/api-module/fn/order-service/get-order';
import { GetOrders$Params } from 'src/app/modules/api-module/fn/order-service/get-orders';
import { OrderDto, PageOfOrderDto } from 'src/app/modules/api-module/models';
import { OrderService } from 'src/app/modules/api-module/services';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrl: './orders-list.component.css'
})
export class OrdersListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['productName', 'netAmount', 'state', 'type', 'number', 'orderReference', 'transactionReferenceId', 'createdDate', 'lastModifiedDate'];
  pageSizeOptions: number[] = [1, 2, 5, 10, 20, 50];
  dataSource = new MatTableDataSource<OrderDto>();
  isLoading: boolean = false;
  errorMessage: string = "";
  searchName: string = "";
  totalItems: number = 0;
  pageSize: number = 5;
  pageIndex: number = 0;

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private orderService: OrderService, private router: Router) { }

  ngOnInit() {
    this.getOrders();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  sortData(sort: Sort) {
    this.dataSource.sort!.direction = sort.direction;
    this.dataSource.sort!.active = sort.active;
    this.getOrders();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getOrders();
  }

  getOrders() {
    this.isLoading = true;
    const sortDirection = this.sort?.direction || 'asc';
    const sortActive = this.sort?.active || 'productName';

    const payload: GetOrders$Params = {
      name: this.searchName,
      pageable: {
        page: this.pageIndex,
        size: this.pageSize,
        sort: [sortActive, sortDirection]
      }
    };

    this.orderService.getOrders(payload).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (data: PageOfOrderDto) => {
        this.dataSource = new MatTableDataSource(data.content);
        this.totalItems = data.totalElements!;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error: string) => {
        this.errorMessage = error;
        console.error('Error fetching orders: ', error);
      }
    );
  }

  navigateToDetails(orderReference: string) {
    this.router.navigate(['/order', orderReference]);
  }

  navigateToCreate() {
    this.router.navigate(['/order']);
  }

}
