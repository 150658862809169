<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{
        "Configuration Properties" | transloco
      }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="!config; else showForm">
        <mat-spinner></mat-spinner>
      </div>
      <ng-template #showForm>
        <mat-list>
          <mat-list-item>
            <strong>{{ "Environment" | transloco }}:</strong> {{ config.env }}
          </mat-list-item>
          <mat-list-item>
            <strong>{{ "Branch" | transloco }}:</strong> {{ config.branch }}
          </mat-list-item>
          <mat-list-item>
            <strong>{{ "Project" | transloco }}:</strong> {{ config.project }}
          </mat-list-item>
          <mat-list-item>
            <strong>{{ "API URL" | transloco }}:</strong> {{ config.apiUrl }}
          </mat-list-item>
          <mat-list-item>
            <strong>{{ "Wallet API URL" | transloco }}:</strong>
            {{ config.walletApiUrl }}
          </mat-list-item>
          <mat-list-item>
            <strong>{{ "Scopes" | transloco }}:</strong> {{ config.scope }}
          </mat-list-item>
          <mat-list-item>
            <strong>{{ "Issuer" | transloco }}:</strong> {{ config.issuer }}
          </mat-list-item>
          <mat-list-item>
            <strong>{{ "Client ID" | transloco }}:</strong>
            {{ config.clientId }}
          </mat-list-item>
          <mat-list-item>
            <strong>{{ "Redirect Uri" | transloco }}:</strong>
            {{ config.redirectUri }}
          </mat-list-item>
        </mat-list>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
