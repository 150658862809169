/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { WalletTransactionHistory } from '../../models/wallet-transaction-history';

export interface ListWalletTransactionHistory$Json$Params {
  walletID: string;

/**
 * page number. defaults to 1
 */
  page?: number;

/**
 * page size. defaults to 10
 */
  size?: number;

/**
 * page number. defaults to 1
 */
  'X-Pagination-Page'?: number;

/**
 * page size. defaults to 10
 */
  'X-Pagination-Size'?: number;

/**
 * specify list ordering in format: fieldname(,asc|desc) or (+|-)fieldname. Multiple order statements are allowed
 */
  order?: Array<string>;

/**
 * limit results to given Transaction types (must be valid codes from the TransactionType enumeration)
 */
  type?: Array<string>;

/**
 * limit results to given Transaction states (must be valid codes from the TransactionState enumeration)
 */
  state?: Array<string>;
}

export function listWalletTransactionHistory$Json(http: HttpClient, rootUrl: string, params: ListWalletTransactionHistory$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WalletTransactionHistory>>> {
  const rb = new RequestBuilder(rootUrl, listWalletTransactionHistory$Json.PATH, 'get');
  if (params) {
    rb.path('walletID', params.walletID, {});
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.header('X-Pagination-Page', params['X-Pagination-Page'], {});
    rb.header('X-Pagination-Size', params['X-Pagination-Size'], {});
    rb.query('order', params.order, {});
    rb.query('type', params.type, {});
    rb.query('state', params.state, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<WalletTransactionHistory>>;
    })
  );
}

listWalletTransactionHistory$Json.PATH = '/api/TransactionHistory/{walletID}';
