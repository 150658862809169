/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getOrganizationalWallet } from '../fn/wallet/get-organizational-wallet';
import { GetOrganizationalWallet$Params } from '../fn/wallet/get-organizational-wallet';
import { getOrganizationalWalletByProviderAndTenant } from '../fn/wallet/get-organizational-wallet-by-provider-and-tenant';
import { GetOrganizationalWalletByProviderAndTenant$Params } from '../fn/wallet/get-organizational-wallet-by-provider-and-tenant';
import { getOrganizationalWalletForMarketplace } from '../fn/wallet/get-organizational-wallet-for-marketplace';
import { GetOrganizationalWalletForMarketplace$Params } from '../fn/wallet/get-organizational-wallet-for-marketplace';
import { getVendorWallet } from '../fn/wallet/get-vendor-wallet';
import { GetVendorWallet$Params } from '../fn/wallet/get-vendor-wallet';
import { getVendorWalletByTenant } from '../fn/wallet/get-vendor-wallet-by-tenant';
import { GetVendorWalletByTenant$Params } from '../fn/wallet/get-vendor-wallet-by-tenant';
import { getWalletById } from '../fn/wallet/get-wallet-by-id';
import { GetWalletById$Params } from '../fn/wallet/get-wallet-by-id';
import { listOrganizationalWalletsByTenant } from '../fn/wallet/list-organizational-wallets-by-tenant';
import { ListOrganizationalWalletsByTenant$Params } from '../fn/wallet/list-organizational-wallets-by-tenant';
import { listOrganizationalWalletsForActiveContext } from '../fn/wallet/list-organizational-wallets-for-active-context';
import { ListOrganizationalWalletsForActiveContext$Params } from '../fn/wallet/list-organizational-wallets-for-active-context';
import { listWalletsByOwner } from '../fn/wallet/list-wallets-by-owner';
import { ListWalletsByOwner$Params } from '../fn/wallet/list-wallets-by-owner';
import { listWalletsForActiveContext } from '../fn/wallet/list-wallets-for-active-context';
import { ListWalletsForActiveContext$Params } from '../fn/wallet/list-wallets-for-active-context';
import { Wallet } from '../models/wallet';

@Injectable({ providedIn: 'root' })
export class WalletService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWalletById()` */
  static readonly GetWalletByIdPath = '/api/Wallet/{walletID}';

  /**
   * Get a wallet by it's ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWalletById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWalletById$Response(params: GetWalletById$Params, context?: HttpContext): Observable<StrictHttpResponse<Wallet>> {
    return getWalletById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a wallet by it's ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWalletById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWalletById(params: GetWalletById$Params, context?: HttpContext): Observable<Wallet> {
    return this.getWalletById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Wallet>): Wallet => r.body)
    );
  }

  /** Path part for operation `listWalletsForActiveContext()` */
  static readonly ListWalletsForActiveContextPath = '/api/Wallet';

  /**
   * Lists Wallets of all types for the Current Tenant. (Market-only Usage)
   * Can be optionally filtered to return only the requested Type(s).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWalletsForActiveContext()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletsForActiveContext$Response(params?: ListWalletsForActiveContext$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Wallet>>> {
    return listWalletsForActiveContext(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists Wallets of all types for the Current Tenant. (Market-only Usage)
   * Can be optionally filtered to return only the requested Type(s).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listWalletsForActiveContext$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletsForActiveContext(params?: ListWalletsForActiveContext$Params, context?: HttpContext): Observable<Array<Wallet>> {
    return this.listWalletsForActiveContext$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Wallet>>): Array<Wallet> => r.body)
    );
  }

  /** Path part for operation `getOrganizationalWallet()` */
  static readonly GetOrganizationalWalletPath = '/api/Wallet/organizational';

  /**
   * Returns the Wallet of Type ORGANIZATIONAL for the active Tenant/userContext (Application-only Usage)
   * When called from an application, this wil return the wallet to be used for purchases by the application, including the current balance.
   * This can NOT be used from the market itself, as there is no specific context available here to select the correct Wallet.
   *
   * Can only be called by applications (NOT Market or related System Applications). Will return Forbidden to unsupported callers.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationalWallet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationalWallet$Response(params?: GetOrganizationalWallet$Params, context?: HttpContext): Observable<StrictHttpResponse<Wallet>> {
    return getOrganizationalWallet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the Wallet of Type ORGANIZATIONAL for the active Tenant/userContext (Application-only Usage)
   * When called from an application, this wil return the wallet to be used for purchases by the application, including the current balance.
   * This can NOT be used from the market itself, as there is no specific context available here to select the correct Wallet.
   *
   * Can only be called by applications (NOT Market or related System Applications). Will return Forbidden to unsupported callers.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationalWallet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationalWallet(params?: GetOrganizationalWallet$Params, context?: HttpContext): Observable<Wallet> {
    return this.getOrganizationalWallet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Wallet>): Wallet => r.body)
    );
  }

  /** Path part for operation `getVendorWallet()` */
  static readonly GetVendorWalletPath = '/api/Wallet/vendor';

  /**
   * Returns the Vendor wallet for the active Tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVendorWallet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVendorWallet$Response(params?: GetVendorWallet$Params, context?: HttpContext): Observable<StrictHttpResponse<Wallet>> {
    return getVendorWallet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the Vendor wallet for the active Tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVendorWallet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVendorWallet(params?: GetVendorWallet$Params, context?: HttpContext): Observable<Wallet> {
    return this.getVendorWallet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Wallet>): Wallet => r.body)
    );
  }

  /** Path part for operation `listOrganizationalWalletsForActiveContext()` */
  static readonly ListOrganizationalWalletsForActiveContextPath = '/api/Wallet/organizational/all';

  /**
   * Lists all Wallets of type ORGANIZATIONAL for the Current Tenant. (Market-only Usage).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrganizationalWalletsForActiveContext()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrganizationalWalletsForActiveContext$Response(params?: ListOrganizationalWalletsForActiveContext$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Wallet>>> {
    return listOrganizationalWalletsForActiveContext(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists all Wallets of type ORGANIZATIONAL for the Current Tenant. (Market-only Usage).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrganizationalWalletsForActiveContext$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrganizationalWalletsForActiveContext(params?: ListOrganizationalWalletsForActiveContext$Params, context?: HttpContext): Observable<Array<Wallet>> {
    return this.listOrganizationalWalletsForActiveContext$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Wallet>>): Array<Wallet> => r.body)
    );
  }

  /** Path part for operation `listOrganizationalWalletsByTenant()` */
  static readonly ListOrganizationalWalletsByTenantPath = '/api/Wallet/{ownerID}/organizational/all';

  /**
   * Lists all Wallets of type ORGANIZATIONAL for the requested Tenant. (Market-only Usage).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrganizationalWalletsByTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrganizationalWalletsByTenant$Response(params: ListOrganizationalWalletsByTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Wallet>>> {
    return listOrganizationalWalletsByTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists all Wallets of type ORGANIZATIONAL for the requested Tenant. (Market-only Usage).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrganizationalWalletsByTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrganizationalWalletsByTenant(params: ListOrganizationalWalletsByTenant$Params, context?: HttpContext): Observable<Array<Wallet>> {
    return this.listOrganizationalWalletsByTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Wallet>>): Array<Wallet> => r.body)
    );
  }

  /** Path part for operation `getOrganizationalWalletByProviderAndTenant()` */
  static readonly GetOrganizationalWalletByProviderAndTenantPath = '/api/Wallet/provider/{providerID}/{ownerID}/organizational';

  /**
   * Returns the Wallet of type ORGANIZATIONAL for the requested Owner and Provider. (Market-only Usage).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationalWalletByProviderAndTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationalWalletByProviderAndTenant$Response(params: GetOrganizationalWalletByProviderAndTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<Wallet>> {
    return getOrganizationalWalletByProviderAndTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the Wallet of type ORGANIZATIONAL for the requested Owner and Provider. (Market-only Usage).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationalWalletByProviderAndTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationalWalletByProviderAndTenant(params: GetOrganizationalWalletByProviderAndTenant$Params, context?: HttpContext): Observable<Wallet> {
    return this.getOrganizationalWalletByProviderAndTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Wallet>): Wallet => r.body)
    );
  }

  /** Path part for operation `getVendorWalletByTenant()` */
  static readonly GetVendorWalletByTenantPath = '/api/Wallet/{ownerID}/vendor';

  /**
   * Returns the Vendor wallet for the requested Tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVendorWalletByTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVendorWalletByTenant$Response(params: GetVendorWalletByTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<Wallet>> {
    return getVendorWalletByTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the Vendor wallet for the requested Tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVendorWalletByTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVendorWalletByTenant(params: GetVendorWalletByTenant$Params, context?: HttpContext): Observable<Wallet> {
    return this.getVendorWalletByTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<Wallet>): Wallet => r.body)
    );
  }

  /** Path part for operation `listWalletsByOwner()` */
  static readonly ListWalletsByOwnerPath = '/api/Wallet/{ownerID}/all';

  /**
   * Lists Wallets of all types for the given Tenant. (Market-only + CloudAmin-only Usage)
   * Can be optionally filtered to return only the requested Type.
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers. Caller must be a CloudAdmin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listWalletsByOwner()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletsByOwner$Response(params: ListWalletsByOwner$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Wallet>>> {
    return listWalletsByOwner(this.http, this.rootUrl, params, context);
  }

  /**
   * Lists Wallets of all types for the given Tenant. (Market-only + CloudAmin-only Usage)
   * Can be optionally filtered to return only the requested Type.
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers. Caller must be a CloudAdmin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listWalletsByOwner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listWalletsByOwner(params: ListWalletsByOwner$Params, context?: HttpContext): Observable<Array<Wallet>> {
    return this.listWalletsByOwner$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Wallet>>): Array<Wallet> => r.body)
    );
  }

  /** Path part for operation `getOrganizationalWalletForMarketplace()` */
  static readonly GetOrganizationalWalletForMarketplacePath = '/api/Wallet/marketplace/{marketplaceID}/organizational';

  /**
   * Returns the Wallet of type ORGANIZATIONAL to be used for purchases though the given Marketplace. (Market-only Usage).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationalWalletForMarketplace()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationalWalletForMarketplace$Response(params: GetOrganizationalWalletForMarketplace$Params, context?: HttpContext): Observable<StrictHttpResponse<Wallet>> {
    return getOrganizationalWalletForMarketplace(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the Wallet of type ORGANIZATIONAL to be used for purchases though the given Marketplace. (Market-only Usage).
   *
   * Can only be called by Market or related System Applications. Will return Forbidden to unsupported callers.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationalWalletForMarketplace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationalWalletForMarketplace(params: GetOrganizationalWalletForMarketplace$Params, context?: HttpContext): Observable<Wallet> {
    return this.getOrganizationalWalletForMarketplace$Response(params, context).pipe(
      map((r: StrictHttpResponse<Wallet>): Wallet => r.body)
    );
  }

}
