/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BillingInformationState } from '../models/billing-information-state';
import { getBillingInformation } from '../fn/billing/get-billing-information';
import { GetBillingInformation$Params } from '../fn/billing/get-billing-information';
import { getVatRate } from '../fn/billing/get-vat-rate';
import { GetVatRate$Params } from '../fn/billing/get-vat-rate';
import { setBillingInformation } from '../fn/billing/set-billing-information';
import { SetBillingInformation$Params } from '../fn/billing/set-billing-information';
import { TaxRate } from '../models/tax-rate';
import { validateBillingInformationForMarketplaceUsage } from '../fn/billing/validate-billing-information-for-marketplace-usage';
import { ValidateBillingInformationForMarketplaceUsage$Params } from '../fn/billing/validate-billing-information-for-marketplace-usage';
import { validateVat } from '../fn/billing/validate-vat';
import { ValidateVat$Params } from '../fn/billing/validate-vat';
import { validateVatByCountry } from '../fn/billing/validate-vat-by-country';
import { ValidateVatByCountry$Params } from '../fn/billing/validate-vat-by-country';
import { validateViesState } from '../fn/billing/validate-vies-state';
import { ValidateViesState$Params } from '../fn/billing/validate-vies-state';
import { VatState } from '../models/vat-state';
import { ViesCheckResult } from '../models/vies-check-result';

@Injectable({ providedIn: 'root' })
export class BillingService  extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBillingInformation()` */
  static readonly GetBillingInformationPath = '/api/Billing/information';

  /**
   * Retrieve the registered billing information for the current Tenant.
   *
   * This is the information that will be used to create invoices (both to customers and to other vendors).
   *             This includes the current state of VAT Validation in VIES and the data obtained from VIES.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBillingInformation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillingInformation$Response(params?: GetBillingInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<BillingInformationState>> {
    return getBillingInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve the registered billing information for the current Tenant.
   *
   * This is the information that will be used to create invoices (both to customers and to other vendors).
   *             This includes the current state of VAT Validation in VIES and the data obtained from VIES.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBillingInformation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBillingInformation(params?: GetBillingInformation$Params, context?: HttpContext): Observable<BillingInformationState> {
    return this.getBillingInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingInformationState>): BillingInformationState => r.body)
    );
  }

  /** Path part for operation `setBillingInformation()` */
  static readonly SetBillingInformationPath = '/api/Billing/information';

  /**
   * Sets or Updates the billing information for the current Tenant.
   * For internal use only. Only for Tenant Admins.
   *
   * This is the information that will be used to create invoices (both to customers and to other vendors).
   *             Note that required fields and validation is stricter when you have a marketplace: VAT and IBAN are then both required and must be valid.
   *             Requirements: Caller is a Tenant Admin and has the wallet-api.billing scope.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setBillingInformation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setBillingInformation$Response(params?: SetBillingInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<BillingInformationState>> {
    return setBillingInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * Sets or Updates the billing information for the current Tenant.
   * For internal use only. Only for Tenant Admins.
   *
   * This is the information that will be used to create invoices (both to customers and to other vendors).
   *             Note that required fields and validation is stricter when you have a marketplace: VAT and IBAN are then both required and must be valid.
   *             Requirements: Caller is a Tenant Admin and has the wallet-api.billing scope.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setBillingInformation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setBillingInformation(params?: SetBillingInformation$Params, context?: HttpContext): Observable<BillingInformationState> {
    return this.setBillingInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingInformationState>): BillingInformationState => r.body)
    );
  }

  /** Path part for operation `validateViesState()` */
  static readonly ValidateViesStatePath = '/api/Billing/validateviesstate';

  /**
   * (Re)Triggers VIES Validation for the BillingInformation.
   *
   * VIES state in billinginfo is updated when a response was obtained from the VIES Service and left as is when the service happens o be unavailable (which happens quite often).
   *             For internal (MARKET) use only. Only for Tenant Admins.
   *             Requirements: Caller is a Tenant Admin and has the wallet-api.billing scope.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateViesState()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateViesState$Response(params?: ValidateViesState$Params, context?: HttpContext): Observable<StrictHttpResponse<ViesCheckResult>> {
    return validateViesState(this.http, this.rootUrl, params, context);
  }

  /**
   * (Re)Triggers VIES Validation for the BillingInformation.
   *
   * VIES state in billinginfo is updated when a response was obtained from the VIES Service and left as is when the service happens o be unavailable (which happens quite often).
   *             For internal (MARKET) use only. Only for Tenant Admins.
   *             Requirements: Caller is a Tenant Admin and has the wallet-api.billing scope.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateViesState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateViesState(params?: ValidateViesState$Params, context?: HttpContext): Observable<ViesCheckResult> {
    return this.validateViesState$Response(params, context).pipe(
      map((r: StrictHttpResponse<ViesCheckResult>): ViesCheckResult => r.body)
    );
  }

  /** Path part for operation `validateBillingInformationForMarketplaceUsage()` */
  static readonly ValidateBillingInformationForMarketplaceUsagePath = '/api/Billing/validate/marketplace';

  /**
   * Validates the known billing information for use by a Marketplace.
   *
   * Checks wether the current billing information is complete enough to be used for clearing profits from the Vendor Wallet.
   *             This requires a complete billing address and name, and if you are VAT liable, a valid VAT number is also needed. The VAT number will be validated through VIES.
   *             Additionally an IBAN bank account number is required.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateBillingInformationForMarketplaceUsage()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateBillingInformationForMarketplaceUsage$Response(params?: ValidateBillingInformationForMarketplaceUsage$Params, context?: HttpContext): Observable<StrictHttpResponse<BillingInformationState>> {
    return validateBillingInformationForMarketplaceUsage(this.http, this.rootUrl, params, context);
  }

  /**
   * Validates the known billing information for use by a Marketplace.
   *
   * Checks wether the current billing information is complete enough to be used for clearing profits from the Vendor Wallet.
   *             This requires a complete billing address and name, and if you are VAT liable, a valid VAT number is also needed. The VAT number will be validated through VIES.
   *             Additionally an IBAN bank account number is required.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateBillingInformationForMarketplaceUsage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateBillingInformationForMarketplaceUsage(params?: ValidateBillingInformationForMarketplaceUsage$Params, context?: HttpContext): Observable<BillingInformationState> {
    return this.validateBillingInformationForMarketplaceUsage$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingInformationState>): BillingInformationState => r.body)
    );
  }

  /** Path part for operation `getVatRate()` */
  static readonly GetVatRatePath = '/api/Billing/vat';

  /**
   * Get the VAT rate that needs to be applied for a SALE to the current Tenant.
   *
   * Only Eu Vendors and Reseller are currently supported.
   * VAT rate may differ depending on wether the current Tenant is a company or a person
   * and their residence.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVatRate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVatRate$Response(params?: GetVatRate$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRate>> {
    return getVatRate(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the VAT rate that needs to be applied for a SALE to the current Tenant.
   *
   * Only Eu Vendors and Reseller are currently supported.
   * VAT rate may differ depending on wether the current Tenant is a company or a person
   * and their residence.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVatRate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVatRate(params?: GetVatRate$Params, context?: HttpContext): Observable<TaxRate> {
    return this.getVatRate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRate>): TaxRate => r.body)
    );
  }

  /** Path part for operation `validateVatByCountry()` */
  static readonly ValidateVatByCountryPath = '/api/Billing/validatevatbycountry/{countryCode}';

  /**
   * Provide SYNTACTICAL Validation of a given VAT number (format, length and modulo), useful for calling from a UI thread to implement client validation.
   *
   * This does not create or modify any billinginformation data or state, and is provided purely as a utility method.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateVatByCountry()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateVatByCountry$Response(params: ValidateVatByCountry$Params, context?: HttpContext): Observable<StrictHttpResponse<VatState>> {
    return validateVatByCountry(this.http, this.rootUrl, params, context);
  }

  /**
   * Provide SYNTACTICAL Validation of a given VAT number (format, length and modulo), useful for calling from a UI thread to implement client validation.
   *
   * This does not create or modify any billinginformation data or state, and is provided purely as a utility method.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateVatByCountry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateVatByCountry(params: ValidateVatByCountry$Params, context?: HttpContext): Observable<VatState> {
    return this.validateVatByCountry$Response(params, context).pipe(
      map((r: StrictHttpResponse<VatState>): VatState => r.body)
    );
  }

  /** Path part for operation `validateVat()` */
  static readonly ValidateVatPath = '/api/Billing/validatevat/{vatNumber}';

  /**
   * Provide SYNTACTICAL Validation of a given VAT number (format, length and modulo), useful for calling from a UI thread to implement client validation.
   *
   * This does not create or modify any billinginformation data or state, and is provided purely as a utility method.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateVat()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateVat$Response(params: ValidateVat$Params, context?: HttpContext): Observable<StrictHttpResponse<VatState>> {
    return validateVat(this.http, this.rootUrl, params, context);
  }

  /**
   * Provide SYNTACTICAL Validation of a given VAT number (format, length and modulo), useful for calling from a UI thread to implement client validation.
   *
   * This does not create or modify any billinginformation data or state, and is provided purely as a utility method.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateVat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validateVat(params: ValidateVat$Params, context?: HttpContext): Observable<VatState> {
    return this.validateVat$Response(params, context).pipe(
      map((r: StrictHttpResponse<VatState>): VatState => r.body)
    );
  }

}
